.wrapper {
  position: fixed;
  right: 10px;
  bottom: 55px;
  z-index: 999999999;
  display: block;
  padding: 2px 2px 2px 10px;
  backdrop-filter: blur(5px);
  border-radius: 5px;
  transition: opacity 300ms ease-in-out;
}

@media (min-width: $md) {
  .wrapper {
    right: 95px;
    bottom: 35px;
  }
}

.app-light {
  &.wrapper {
    background: hsl($colorWhite / 90%);
    border: 1px solid hsl($colorGrey200);
  }
}

.app-dark {
  &.wrapper {
    background: hsl($colorBlack / 90%);
    border: 1px solid hsl($colorGrey850);
  }
}
