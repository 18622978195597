html {
  color: hsl($colorGrey900);
  color-scheme: light;
  background: hsl($colorGrey50);

  &.dark {
    color: hsl($colorGrey50);
    color-scheme: dark;
    background: hsl($colorGrey950);
  }

  &.dark-blue {
    color-scheme: dark;
    background: hsl($colorBrandBlue950);
  }
}

body {
  padding: 0;
  margin: 0;
  font: normal 1rem/1.5 $fontFamily;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 3px solid hsl($colorBrandBlue200);
  }
}

button {
  font-family: inherit;
}

svg {
  display: block;
  fill: currentColor;
}

h1 {
  margin: 0 0 20px;
  font-size: rem-convert(28px);
}

@media (min-width: $sm) {
  h1 {
    margin: 0 0 30px;
    font-size: rem-convert(34px);
  }

  h2 {
    font-size: rem-convert(28px);
  }
}

/* GRID */
.flex {
  display: flex;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-stretch {
  align-items: stretch;
}

/* OTHER */
.text-one-line {
  white-space: nowrap;
}

/* SPACING */
@each $type, $property in (m, p), (margin, padding) {
  @each $prefix, $size in (0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 60, 80),
    (0, 2px, 3px, 4px, 5px, 6px, 8px, 10px, 12px, 16px, 20px, 24px, 28px, 32px, 34px, 45px) {
    .$(type)b-$(prefix) {
      $(property)-bottom: $(size) !important;
    }

    .$(type)r-$(prefix) {
      $(property)-right: $(size) !important;
    }

    .$(type)l-$(prefix) {
      $(property)-left: $(size) !important;
    }
  }
}

@media (min-width: $md) {
  @each $type, $property in (m, p), (margin, padding) {
    @each $prefix, $size in (0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 60, 80),
      (0, 2px, 4px, 6px, 8px, 10px, 12px, 16px, 20px, 24px, 28px, 32px, 40px, 48px, 60px, 80px) {
      .$(type)b-$(prefix) {
        $(property)-bottom: $(size) !important;
      }

      .$(type)r-$(prefix) {
        $(property)-right: $(size) !important;
      }

      .$(type)l-$(prefix) {
        $(property)-left: $(size) !important;
      }
    }
  }
}
