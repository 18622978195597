.code {
  font-family: $fontFamilyMono;
  font-size: rem-convert(110px);
  font-weight: $fontLight;
}

@media (min-width: $md) {
  .container {
    height: 90%;
  }

  .app-light.separator {
    &::after {
      background: hsl($colorGrey400);
    }
  }

  .app-dark.separator {
    &::after {
      background: hsl($colorGrey700);
    }
  }

  .separator {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    height: 260px;

    &::after {
      width: 1px;
      height: 100%;
      content: "";
    }
  }
}
