/* stylelint-disable selector-class-pattern */
.wrapper {
  width: 100%;
  max-width: 430px;
  font-size: rem-convert(15px);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 10px;
  appearance: none;
  background: none;
  border: none;
  opacity: 0.5;
  transition: opacity 300ms ease-in-out;

  svg {
    pointer-events: none;
    fill: hsl($colorWhite);
  }

  &:hover {
    opacity: 0.7;
  }
}

.content {
  padding: 0;

  :global {
    .Toastify__toast {
      padding: 15px 40px 15px 15px;
      margin-bottom: 5px;
      line-height: 26px;
      color: white;
      backdrop-filter: blur(2px);

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
        height: 4px;
        content: "";
        background-color: hsl(0deg 0% 100% / 70%);
        opacity: 0.3;
      }
    }

    .Toastify__toast-body {
      align-items: flex-start;
      width: 100%;
      padding: 0;
    }

    .Toastify__progress-bar {
      height: 4px;
      border-radius: 2px;
    }

    .Toastify__toast-icon {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
      margin: 5px 10px 0 0;
      transform: translateY(-2px);

      svg {
        fill: currentColor;
      }
    }

    .Toastify__toast--success {
      .Toastify__progress-bar {
        background-color: hsl($colorSuccess600);
      }

      .Toastify__toast-icon {
        color: hsl($colorSuccess600);
      }

      &::after {
        background-color: hsl($colorSuccess600);
      }
    }

    .Toastify__toast--info {
      .Toastify__progress-bar {
        background-color: hsl($colorInfo500);
      }

      .Toastify__toast-icon {
        color: hsl($colorInfo500);
        transform: translateY(-2px) rotate(180deg);
        transform-origin: center;
      }

      &::after {
        background-color: hsl($colorInfo500);
      }
    }

    .Toastify__toast--error {
      .Toastify__progress-bar {
        background-color: hsl($colorError500);
      }

      .Toastify__toast-icon {
        color: hsl($colorError500);
      }

      &::after {
        background-color: hsl($colorError500);
      }
    }
  }
}

/* THEMES */
.wrapper.dark {
  :global {
    .Toastify__toast {
      background-color: hsl($colorGrey850);
      box-shadow: 0 0 40px hsl($colorBlack / 8%);
    }
  }
}

.wrapper.light {
  :global {
    .Toastify__toast {
      background-color: hsl($colorGrey900 / 95%);
      box-shadow: 0 0 40px hsl($colorBlack / 10%);
    }
  }
}

@media (min-width: $sm) {
  .content {
    width: 430px !important;

    :global {
      .Toastify__toast {
        padding: 25px 40px 25px 20px;
        border-radius: 4px;
      }

      .Toastify__toast-icon {
        margin-right: 20px;
        transform: translateY(-1px);
      }
    }
  }
}
